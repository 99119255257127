import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/homelayout';
import SEO from '../components/seo';

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Privacy Notice"
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="contact-above">
        <Row>
          <Col>
            <h1 className="privacy-heading">Privacy Notice</h1>
          </Col>
        </Row>
      </Container>
      <Container className="contactpage fullwidth">
        <Container>
          <Row className="common-text">
            <p>
              Hi and welcome to what we hope will be the kind of Privacy Notice
              you end up wanting to read - because this bit&rsquo;s written in
              easy-to-digest language and{' '}
              <strong>tells you the parts you most need to know</strong> in a
              way that will make sense.
            </p>
            <p>
              However, we, of course, understand that at times you might
              actually want to read the long, legalese version, so just to be
              sure, that&rsquo;s further down for you. Whichever version you
              read, if you have any questions, definitely reach out to us and
              we&rsquo;ll be happy to help.
            </p>
            <p>
              <strong>
                So, why do we have a Privacy Notice? Well, you&rsquo;re legally
                entitled to know what data of yours we keep on our servers, as
                well as how it&rsquo;s used, and we&rsquo;re legally obliged to
                tell you&hellip; something that we&rsquo;re happy to do.
              </strong>
            </p>
            <p>
              In terms of the LeverID app itself, if you&rsquo;ve downloaded and
              want to use it, there are pieces of data that we keep and there
              are others that our trusted verification partner, Onfido, keeps.
            </p>
            <p>
              <strong>All that we keep is your name</strong>{' '}
              <em>(as entered when you register)</em>{' '}
              <strong>
                and whatever identification code you used to confirm your
                identity
              </strong>{' '}
              <em>(the one issued by your local authority)</em>
              <strong>.</strong>
            </p>
            <p>
              The Leversign product doesn&rsquo;t even store that data, beyond
              you having registered with a name, which produces an ID number. We
              only store the ID number.
            </p>
            <p>
              <strong>
                That&rsquo;s it, folks, that&rsquo;s all we keep at our end! Not
                even your shoe size. Just what we mention now.
              </strong>
            </p>
            <p>
              The stuff that Onfido keeps are things like facial imagery and
              sound files, out of necessity - full details in Section 2 of the
              long version - oh and they have a Privacy Notice too -{' '}
              <a href="https://onfido.com/privacy/">
                <strong>here</strong>
              </a>
              !
            </p>
            <p>
              Why do we &lsquo;keep&rsquo; some of your data anyway?{' '}
              <strong>
                Well, if we don&rsquo;t, neither LeverID nor Leversign will
                work. Simples.
              </strong>{' '}
              It&rsquo;s the same with Onfido - we both only keep what we need
              to ensure the app works and your identity is always verifiable.
              Also simples.{' '}
              <strong>
                Otherwise, you&rsquo;d have to go through identity
                re-verification and re-registration every time you opened the
                app.
              </strong>{' '}
              Which would kinda kill the convenience element&hellip;
            </p>
            <p>
              <strong>
                Here&rsquo;s the reassuring bit - we don&rsquo;t sell your data
                to anyone else; we don&rsquo;t even reveal it to anyone else
                unless we a) have to{' '}
              </strong>
              <em>(by law)</em>
              <strong> and b) need to</strong>{' '}
              <em>(in order to keep the services working)</em> - and if we need
              to do that, we take appropriate steps to ensure security of your
              data. See 3.1 and 3.2 of the long version for more info on that
              bit.
            </p>
            <p>
              By the way, you have a whole host of rights when it comes to
              knowing stuff about what we do - and we&rsquo;ve listed them in
              the long version,
              <strong>
                {' '}
                see part 5 there - everything you have the right to know and how
                you can exercise such rights.
              </strong>
            </p>
            <p>
              Here&rsquo;s another thing about data and privacy notices -
              we&rsquo;re called a &lsquo;Data Controller&rsquo; but only
              because we&rsquo;re in <em>(necessary)</em> possession of your
              data and we need to control{' '}
              <em>(which in many languages also means &ldquo;check&rdquo;)</em>{' '}
              that it is not misused in any way. It&rsquo;s not like we control
              you 🙂
            </p>
            <p>
              <strong>
                Yes, it&rsquo;s our product - but it&rsquo;s your
                data&hellip;.and we work hard to keep it secure.
              </strong>
            </p>
            <p>
              So, thank you for trusting us with it - we hope the above{' '}
              <em>(and the below)</em> will give you the reassurance you need so
              you can click &lsquo;accept&rsquo; <em>(in the app)</em>, relax,
              and just enjoy the convenience that our service has been designed
              to provide you with.
            </p>
            <p>
              If you need any more info, or have a question, or just want to
              tell us how your day has been made easier by using LeverID or
              Leversign, we&rsquo;re happy to hear from you - drop us a message
              on{' '}
              <strong>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </strong>{' '}
              any time.
            </p>
            <p>The LeverID Team</p>
            <br />
            <br />
            <p>
              <strong>
                <em>(And now the legalese version. Enjoy!</em>
              </strong>{' '}
              😀
              <strong>
                <em>)</em>
              </strong>
            </p>
            <p>
              This privacy notice (hereinafter &ldquo;notice&rdquo;) explains
              how Lever ID O&Uuml; (hereinafter &ldquo;we&rdquo; or
              &ldquo;us&rdquo;) processes personal data of users (hereinafter
              &ldquo;you&rdquo; or the &ldquo;data subject&rdquo;) of the
              LeverID application available on iOS and Android platforms
              (hereinafter &ldquo;application&rdquo;).
            </p>
            <p>
              This notice describes the processing of personal data by us as a
              data controller. This means that we individually, or along with
              others, determine the purposes and means of the processing of
              personal data.
            </p>
            <p>
              As stated above, this privacy notice provides information on
              processing of personal data in regard to the application. In order
              to run the application, you provide us with your name, a personal
              code of your country of residence and an email address. In this
              way, we process your personal data as little as possible. However,
              processing of certain personal data is unavoidable, otherwise the
              application will simply not work properly on your device. The
              personal data we need to process for that reason is described in
              Section 2 below.
            </p>
            <p>
              <strong>1. DATA CONTROLLER</strong>
            </p>
            <p>Lever ID O&Uuml;</p>
            <p>Registry code: 16410674</p>
            <p>
              Address: A. H. Tammsaare tee 47, Kristiine linnaosa, Harju
              maakond, Tallinn, 11316, Estonia.
            </p>
            <p>
              Email:{' '}
              <strong>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </strong>
            </p>
            <p>
              Contact details of Data Protection Officer: [
              <strong>
                <a href="mailto:gilbert.pardla@levercode.com">
                  gilbert.pardla@levercode.com
                </a>
              </strong>
              ]
            </p>
            <p>
              <strong>
                2. THE PURPOSES AND THE LEGAL GROUNDS FOR THE PROCESSING OF
                PERSONAL DATA
              </strong>
            </p>
            <p>
              When we process your personal data, we rely on the following legal
              ground: processing is necessary for the performance of a contract
              to which you are the party, or in order to take steps, at your
              request, prior to entering into a contract.
            </p>
            <p>Purposes of processing:</p>
            <p>Personal data that we process:</p>
            <p>
              LeverID does not collect face data but, in order to use LeverID,
              we must verify your identity. LeverID uses the Onfido Biometric
              Verification service &lsquo;SDK&rsquo;, which records a video of
              the user speaking a number and moving their head to a specified
              direction. We do use Apple&rsquo;s Face ID functionality to enter
              Pin 1 or Pin 2, although this can be toggled on or off by the
              user. LeverID only stores in its servers your name and your
              personal country code. Onfido Stores all &lsquo;KYC&rsquo;
              process-related personal information on its own servers.
            </p>
            <p>
              We use Onfido as a &lsquo;KYC&rsquo; provider to verify a user's
              identity via uploading a photo-based identity document, a selfie
              image and artificial intelligence algorithms. When verifying the
              identity of a user, Onfido will ask for an image, or images, of
              the user&rsquo;s identity document, as well as a picture or video
              of their face. Onfido then seeks to verify whether the identity
              document is likely to be genuine and whether the person in the
              photo or video is likely to be the same person pictured in the
              identity document. We will also look to identify signs of fraud
              (for example, someone wearing a mask to impersonate another person
              or to conceal their own real identity). If the user is successful
              on both the document and facial verification checks, Onfido will
              likely consider the user to have proven their identity. In some
              cases, Onfido may also further check whether or not we have
              previously verified you as a user for LeverID, (e.g., on two
              different devices, such as on iOS and Android) by comparing the
              picture of your face to any picture previously provided by you.
              This helps us to not only verify your identity, but it further
              protects you by helping us to understand whenever a user may be
              attempting to generate multiple identities.
            </p>
            <p>
              During the KYC stage of registration, your data is processed only
              within Onfido and is not shared with any other parties at that
              point.
            </p>
            <p>
              To complete the registration process, the following personal data
              is required by both LeverID and Onfido for the provision of our
              services, i.e., to allow you to use the application.
            </p>
            <p>
              Name, country&rsquo;s personal code, email, your device&rsquo;s
              ID, public keys, MAC key, encrypted PINs (authentication and
              signing); unique ID (LeverID in UUID form) that is strictly bound
              to the device and remembered in the core of the application as an
              application/user identifier.
            </p>
            <p>
              Sources of collection of personal data: we receive the personal
              data from you when you download, install and launch the
              application on your device.
            </p>
            <p>
              Once the registration and KYC processes are completed, the only
              data that LeverID retains on its servers is your name (as entered
              during registration) and the personal country code as issued by
              your local authority. Onfido keeps all the data from the identity
              document used, the facial imagery and the audio file in an
              encrypted form on its servers and their own privacy notice can be
              found{' '}
              <a href="https://onfido.com/privacy/">
                <strong>here</strong>
              </a>
              .
            </p>
            <p>
              <strong>
                3. CATEGORIES OF RECIPIENTS OF PERSONAL DATA AND TRANSFER OF
                PERSONAL DATA TO THIRD COUNTRIES
              </strong>
            </p>
            <p>
              In some cases, we may transfer your personal data to certain
              recipients who are categorized as follows:
            </p>
            <p>
              3.1. Partners who provide the services to us and who process the
              personal data on our behalf (data processors), e.g.:
            </p>
            <p>
              (a) Providers of ICT services (including providers of website and
              platform hosting services and other providers of cloud services)
            </p>
            <p>(b) Various contractors (IT development, support)</p>
            <p>
              3.2. Public authorities and supervisory bodies, e.g., court, law
              enforcement authorities, Data Protection Inspectorate. We transfer
              your personal data to public authorities and supervisory bodies
              only if the law requires it.
            </p>
            <p>
              Unless necessary for the provision of services, we do not transfer
              your personal data outside the European Union (EU) or the European
              Economic Area (EEA), nor to such third country or international
              organization the level of data protection of which the European
              Commission has not considered adequate. If your personal data is
              transferred outside of the EU or EEA, such transfer of personal
              data will take place only upon appropriate legal basis and we will
              take appropriate protective measures.
            </p>
            <p>
              You have the right to receive additional information about the
              transfer of your personal data by sending us the relevant request
              at{' '}
              <strong>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </strong>
              .
            </p>
            <p>
              <strong>4. RETENTION OF PERSONAL DATA</strong>
            </p>
            <p>
              We keep your personal data for the period necessary for the
              achievement of purposes stated in this notice or until the law
              requires it. LeverID retains only the user's name and personal
              country code for the time that a user is using our services.
              Onfido retains data required only for verifying your identity.
            </p>
            <p>
              Specific terms of retention can be exercised by accessing your
              personal data. Please see the explanation in Section 5,
              &ldquo;Your rights regarding the personal data&rdquo;. LeverID
              only collect your name and personal code
            </p>
            <p>
              <strong>5. YOUR RIGHTS REGARDING THE PERSONAL DATA</strong>
            </p>
            <p>You have the right to know:</p>
            <ul>
              <li>
                whether or not personal data concerning you are being processed
              </li>
              <li>what the purpose of processing is</li>
              <li>what the categories of personal data are</li>
              <li>
                to whom the personal data is disclosed (especially the
                recipients in third countries)
              </li>
              <li>for how long the personal data is retained</li>
              <li>
                what your rights concerning rectification, erasure and
                restriction of the processing are
              </li>
            </ul>
            <p>
              Right of rectification: you have the right to demand rectification
              of the personal data concerning you if the data are inaccurate or
              incomplete.
            </p>
            <p>
              Right of erasure: in some cases, you have the right to demand
              erasure of the personal data concerning you, for example in case
              when you withdraw your consent and there are no other legal
              grounds for the processing of the personal data.
            </p>
            <p>
              Right to restrict the processing: in some cases, you have the
              right to restrict processing of the personal data concerning you
              for a certain time (e.g., if you have objected to the processing
              of personal data).
            </p>
            <p>
              Right to object: you have the right to object to the processing of
              personal data, which is processed based on the legitimate
              interest, including profiling. Upon objection, we will no longer
              process the personal data unless we demonstrate compelling
              legitimate grounds for the processing which override your
              interests, rights and freedoms. Please be aware though that this
              will render the application unusable as we will no longer be able
              to &lsquo;prove beyond reasonable doubt&rsquo; via Onfido that
              your identity integrity is secure.
            </p>
            <p>
              Right to data portability: if processing your personal data is
              based on your consent or the contract with us, and the data
              processing is carried out by automated means, then you have the
              right to receive the personal data concerning you, which you have
              provided to us, in a structured, commonly used and
              machine-readable format. Also, you have the right to claim to
              transmit those personal data to another service provider, if it is
              technically possible.
            </p>
            <p>
              Right to turn to us, a supervisory authority, or a court: if you
              want to exercise the above-mentioned rights, please send us an
              email at{' '}
              <strong>
                <a href="mailto:info@leverid.com">info@leverid.com</a>
              </strong>
              . If you find that your rights have been breached, you have the
              right to turn to the Data Protection Inspectorate (Andmekaitse
              Inspektsioon) and/or court. The contact details of the Data
              Protection Inspectorate are available at{' '}
              <a href="http://www.aki.ee/">
                <strong>www.aki.ee</strong>
              </a>
              .
            </p>
            <p>
              <strong>6. AMENDMENT OF THIS NOTICE</strong>
            </p>
            <p>
              We have the right to amend this notice unilaterally. We will
              notify users of any amendment to this notice via an updated
              version of the application, or by email or in other appropriate
              manner.
            </p>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export default IndexPage;
